// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import * as Constants from '../../../../constants'
import { getUserData, getHearderAuth, getUid } from '@utils'
// ** Axios Imports
import axios from 'axios'

export const getUserProfile = createAsyncThunk('appChat/getTasks', async () => {
  const userDf = localStorage.getItem('user_df');
  let userData;
  if (userDf) {
    userData = JSON.parse(userDf);
  } else {
    userData = getUserData();
  }

  const profileUser = {
    id: userData.id,
    avatar: userData?.avatar,
    fullName: userData?.fullName,
    email: userData?.email,
    role: userData?.role,
    about: '',
    status: 'online',
    settings: {
      isTwoStepAuthVerificationEnabled: true,
      isNotificationsOn: false
    }
  }
  return profileUser
})

export const reloadDataChats = createAsyncThunk('appChat/reloadDataChats', async () => {
  let list_chats = []
  let list_contact = []
  if (localStorage.getItem("contact_data") && localStorage.getItem("chat_data")) {
    list_contact = JSON.parse(localStorage.getItem("contact_data"))
    list_chats = JSON.parse(localStorage.getItem("chat_data"))
  }
  const userData = getUserData()
  const profileUserData = {
    id: userData.id,
    avatar: userData.avatar,
    fullName: userData.fullName,
    status: 'online'
  }
  const datachatsContacts = list_chats.map(chat => {
    const contact = list_contact.find(c => c.id === chat.userId)
    if (contact) {
      contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
    }
    return contact
  })
  return { chatsContacts: datachatsContacts, contacts: list_contact, profileUser: profileUserData }
})

export const getChatContacts = createAsyncThunk('appChat/getChatContacts', async (page = 1, { dispatch }) => {
  var list_chats = []
  var list_contact = []

  const token_sh = localStorage?.getItem("token_sh");
  const additionalHeaders = token_sh ? [{ key: "X-WorkSuite-Token-SH", value: token_sh }] : [];

  // Lấy thông tin đầy đủ của user từ localStorage
  const userDfString = localStorage.getItem('user_df');
  let userDf = null;
  if (userDfString) {
    try {
      userDf = JSON.parse(userDfString);
    } catch (error) {
      console.error('Error parsing user_df from localStorage:', error);
    }
  }

  const config = {
    method: 'get',
    url: `${Constants.REST_API_WORKSUITE_V3}messages/list-user-chat?page=${page}${userDf ? `&user_df=${userDf.id}` : ''}`,
    headers: getHearderAuth(true, additionalHeaders)
  };

  sessionStorage.setItem('pageC', page)
  if (page > 1) {
    list_contact = JSON.parse(localStorage.getItem("contact_data"))
    list_chats = JSON.parse(localStorage.getItem("chat_data"))
  }
  const emailPattern = /@c\.us|@g\.us/g;
  try {
    const response = await axios(config)
    const contacts = response.data?.data || []
    if (response.data?.next_page_url) {
      sessionStorage.setItem("lmchat_data", 1);
    } else {
      sessionStorage.removeItem("lmchat_data");
    }

    const chatPromises = contacts.map(user => {
      const temp_u = {
        id: user.id,
        uuid: user.uuid,
        fullName: user.name,
        role: user.user_other_role,
        about: '',
        avatar: user.image_url,
        status: 'away',
        waba: emailPattern.test(user.email) ? user.email.replace(emailPattern, '') : 0,
        mess: (user.email.indexOf("@facebook.com") != -1 || user.email.indexOf("@facebook.com") != -1 ? user.email.replace("@facebook.com", "") : 0),
        email: user.email,
        company_name: user.company_name,
        register_date: user.ucreated_at,
        country_name: (user.country_id ? user?.country_name : ''),
        gender: (user.gender ? user.gender : '')
      }

      const existingIndex = list_contact.findIndex(contact => contact.id === user.id);
      if (existingIndex !== -1) {
        list_contact[existingIndex] = temp_u;
      } else {
        list_contact.push(temp_u);
      }

      localStorage.setItem("contact_data", JSON.stringify(list_contact))

      const contactConfig = {
        method: 'get',
        url: `${Constants.REST_API_WORKSUITE_V3}messages/chat-message/${user.id}${userDf ? `?user_df=${userDf.id}` : ''}`,
        headers: getHearderAuth(true, additionalHeaders)
      }
      return axios(contactConfig).then(response => ({
        id: user.id,
        chatData: response.data
      })).catch(error => {
        console.log(error)
        return { id: user.id, chatData: null }
      })
    })
    // console.log(chatPromises.length);
    const chatResponses = await Promise.all(chatPromises)
    chatResponses.forEach(chatResponse => {
      const res_chat = chatResponse.chatData
      const arr_chat_origin = res_chat.data
      if (arr_chat_origin.length > 0) {
        let dem = 0
        const id_user = parseInt(res_chat.idu)
        const temp_chat = {
          id: id_user,
          userId: id_user,
          unseenMsgs: 0,
          chat: []
        }
        if (res_chat.idwb || res_chat.idme) {
          const userData = getUserData()
          userData.waba = res_chat?.idwb
          userData.mess = res_chat?.idme
          localStorage.setItem('userData', JSON.stringify(userData))
        }

        const regex = /\w+:[^ ]+/gm
        let data_up_media = []
        const arr_chat = arr_chat_origin.slice().reverse();
        for (let i = 0; i < arr_chat.length; i++) {
          let data_mess = arr_chat[i].message
          if (arr_chat[i].message.includes('http') && !arr_chat[i].message.includes('drive.cloodo.com') && arr_chat[i].type != 'image') {
            let m
            while ((m = regex.exec(arr_chat[i].message)) !== null) {
              if (m.index === regex.lastIndex) {
                regex.lastIndex++
              }
              m.forEach((match, groupIndex) => {
                const link_mess = match.replace('"', '')
                const str_tag_a = '"' + link_mess + '"'
                if (arr_chat[i].message.indexOf(str_tag_a) === -1) {
                  data_mess = arr_chat[i].message.replace(link_mess, '<a href="' + link_mess + '" target="_blank">' + link_mess + '</a>')
                }
              });
            }
          }
          const obj_chat = {
            kid: arr_chat[i].id,
            message: data_mess,
            time: arr_chat[i].created_at,
            senderId: arr_chat[i].from,
            senderName: '',
            type: (arr_chat[i].type ? arr_chat[i].type : 'chat'),
            heart: arr_chat[i].heart
          }

          try {
            const map_size = new Map(JSON.parse(sessionStorage.getItem('sizeEntry')))
            if (obj_chat.type === 'image' && obj_chat.message.includes('https://') && map_size) {
              const start_cut = "entries/"
              const file_id = data_mess.substring(
                data_mess.indexOf(start_cut) + (start_cut.length),
                data_mess.lastIndexOf("?")
              )
              if (file_id) {
                if (map_size.has(file_id)) {
                  const arr_size = map_size.get(file_id).split("|")
                  if (arr_size.length == 2) {
                    obj_chat.width = arr_size[0]
                    obj_chat.height = arr_size[1]
                  }
                }
              }
            }
          } catch (error) {
            console.log(error)
          }

          const index_contact3 = list_contact.findIndex(c => c.id === arr_chat[i].from)
          if (index_contact3 !== -1) {
            obj_chat.senderName = list_contact[index_contact3].fullName
          }

          if (arr_chat[i].message_seen === 'no' && arr_chat[i].from !== getUid() && arr_chat[i].from !== getUserData().waba && arr_chat[i].from !== getUserData().mess) {
            dem += 1
          }
          temp_chat.chat.push(obj_chat)

          if (obj_chat.type !== 'chat' && !obj_chat.message.includes('https://')) {
            data_up_media.push(obj_chat.message)
          }
        }
        temp_chat.unseenMsgs = dem
        if (data_up_media.length > 0) {
          sessionStorage.setItem('upmedia', JSON.stringify(data_up_media))
        }
        const index_contact = list_chats.findIndex(c => c.id === id_user)
        if (index_contact >= 0) {
          list_chats[index_contact] = temp_chat
        } else {
          list_chats.push(temp_chat)
        }

        localStorage.setItem("chat_data", JSON.stringify(list_chats))
        if (list_contact.length === list_chats.length) {
          dispatch(reloadDataChats())
        }
      }
    })

  } catch (error) {
    console.log(error)
  }

  const config2 = {
    method: 'get',
    url: `${Constants.REST_API_WORKSUITE_V3}messages/list-contact-chat`,
    headers: getHearderAuth()
  }
  await axios(config2)
    .then(function (response) {
      const updateContactList = (userData, contacts, type, list) => {
        let processed = 0;
        list.forEach(item => {
          // if (processed >= 100) return;
          const contactIndex = contacts.findIndex(c => c.id === item.id);
          if (contactIndex === -1) {
            if (item.id !== userData.id) {
              const temp = {
                id: item.id,
                fullName: item.name,
                role: type === 'employee' ? item.user_other_role : type,
                about: '',
                avatar: item.image_url,
                waba: emailPattern.test(item.email) ? item.email.replace(emailPattern, '') : 0,
                mess: item.email.includes("@facebook.com") ? item.email.replace("@facebook.com", "") : 0,
                email: item.email,
                status: 'away'
              };
              contacts.push(temp);
              processed++;
            }
          } else {
            contacts[contactIndex].role = type === 'employee' ? item.user_other_role : type;
          }
        });
      };

      const userData = getUserData();
      const list_contact_ext = response.data;
      const { admins, clients, employees } = list_contact_ext;

      if (clients) {
        updateContactList(userData, list_contact, 'client', clients);
      }
      if (employees) {
        updateContactList(userData, list_contact, 'employee', employees);
      }
      if (admins) {
        updateContactList(userData, list_contact, 'admin', admins);
      }
      localStorage.setItem("contact_data", JSON.stringify(list_contact))
      // console.log(list_contact)
    })
    .catch(function (error) {
      console.log(error)
    })

  const userData = getUserData()
  const profileUserData = {
    id: userData.id,
    avatar: userData.avatar,
    fullName: userData.fullName,
    status: 'online'
  }

  const datachatsContacts = list_chats.map(chat => {
    const contact = list_contact.find(c => c.id === chat.userId)
    contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }
    return contact
  })

  if (list_chats.length === 0) {
    localStorage.setItem('chat_data', '[]')
  }
  return {
    chatsContacts: datachatsContacts,
    contacts: list_contact,
    profileUser: profileUserData,
    currentUser: userDf
  }
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (id, { dispatch }) => {
  const userData = getUserData()
  const userId = Number(id)
  sessionStorage.setItem("active_chat", userId)
  const contacts = JSON.parse(localStorage.getItem("contact_data"))
  const chats = JSON.parse(localStorage.getItem("chat_data"))
  // console.log(chats)
  const FormData = require('form-data')
  let chat = chats.find(c => c.userId === userId)
  const contact = contacts.find(c => c.id === userId)

  if (contact.chat) contact.chat.unseenMsgs = 0

  const index_up = chats.findIndex(c => c.userId === userId)
  let data_up_media = []
  if (index_up >= 0) {
    const arr_chat_curr = chats[index_up].chat
    for (let i = 0; i < arr_chat_curr.length; i++) {
      const type_chat = (arr_chat_curr[i].type ? arr_chat_curr[i].type : 'chat')
      if (type_chat != 'chat') {
        if (arr_chat_curr[i].message.includes('https://')) {
          try {
            const map_size = new Map(JSON.parse(sessionStorage.getItem('sizeEntry')))
            if (map_size) {
              const start_cut = "entries/"
              const file_id = arr_chat_curr[i].message.substring(
                arr_chat_curr[i].message.indexOf(start_cut) + (start_cut.length),
                arr_chat_curr[i].message.lastIndexOf("?")
              )
              if (file_id) {
                if (map_size.has(file_id)) {
                  const arr_size = map_size.get(file_id).split("|")
                  if (arr_size.length == 2) {
                    chats[index_up].chat[i].width = arr_size[0]
                    chats[index_up].chat[i].height = arr_size[1]
                  }
                }
              }
            }
          } catch (error) {
            console.log(error)
          }
        } else {
          data_up_media.push(arr_chat_curr[i].message)
        }
      }
      // Retrieve the senderName cache from localStorage
      let senderNameCache = JSON.parse(localStorage.getItem('senderNameCache') || '{}');
      // If senderName is still empty, check cache or call API
      if (arr_chat_curr[i].senderName === '') {
        if (senderNameCache[arr_chat_curr[i].senderId]) {
          // Use cached name
          chats[index_up].chat[i].senderName = senderNameCache[arr_chat_curr[i].senderId];
        } else {
          // Fetch name from API and update cache
          try {
            const response = await axios.get(`${Constants.CONVERT_CLOODO}infor-user/${arr_chat_curr[i].senderId}`);
            const data = response.data;
            if (data && data.length > 0 && data[0].name) {
              chats[index_up].chat[i].senderName = data[0].name;
              senderNameCache[arr_chat_curr[i].senderId] = chats[index_up].chat[i].senderName;
            }
          } catch (error) {
            console.error(`Failed to fetch senderName for senderId ${arr_chat_curr[i].senderId}`, error);
          }
        }
        // Save the updated senderName cache back to localStorage
        localStorage.setItem('senderNameCache', JSON.stringify(senderNameCache));
      }
    }
    if (chats[index_up].unseenMsgs > 0) {
      chats[index_up].unseenMsgs = 0
      localStorage.setItem("chat_data", JSON.stringify(chats))

      const datas = new FormData()
      datas.append('userID', userId)
      if (userData.waba) {
        datas.append('userWB', userData.waba)
      }
      if (userData.mess) {
        datas.append('userMe', userData.mess)
      }

      const config = {
        method: 'post',
        url: `${Constants.REST_API_WORKSUITE_V3}messages/change-status-message`,
        headers: getHearderAuth(),
        data: datas
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  } else {
    const config2 = {
      method: 'get',
      url: `${Constants.REST_API_WORKSUITE_V3}messages/chat-message/${userId}${userDf ? `&user_df=${userDf.id}` : ''}`,
      headers: getHearderAuth()
    }
    await axios(config2)
      .then(function (response2) {
        const res_chat = response2.data
        const arr_chat = res_chat.data
        if (arr_chat.length > 0) {
          let dem = 0
          const id_user = parseInt(res_chat.idu)
          const temp_chat = {
            id: id_user,
            userId: id_user,
            unseenMsgs: 0,
            chat: []
          }
          if (res_chat.idwb) {
            const userData = getUserData()
            userData.waba = res_chat.idwb
            localStorage.setItem('userData', JSON.stringify(userData))
          }

          const regex = /\w+:[^ ]+/gm
          for (let i = 0; i < arr_chat.length; i++) {
            let data_mess = arr_chat[i].message
            if (arr_chat[i].message.includes('http') && !arr_chat[i].message.includes('drive.cloodo.com') && arr_chat[i].type != 'image') {
              let m
              while ((m = regex.exec(arr_chat[i].message)) !== null) {
                if (m.index === regex.lastIndex) {
                  regex.lastIndex++
                }
                m.forEach((match, groupIndex) => {
                  const str_tag_a = '"' + match + '"';
                  if (!arr_chat[i].message.includes(str_tag_a)) {
                    data_mess = arr_chat[i].message.replace(match, '<a href="' + match + '" target="_blank">' + match + '</a>')
                  }
                });
              }
            }
            const obj_chat = {
              message: data_mess,
              time: arr_chat[i].created_at,
              senderId: arr_chat[i].from,
              senderName: '',
              type: (arr_chat[i].type ? arr_chat[i].type : 'chat')
            }

            const index_contact3 = contacts.findIndex(c => c.id === arr_chat[i].from)
            if (index_contact3 !== -1) {
              obj_chat.senderName = contacts[index_contact3].fullName
            }

            if (arr_chat[i].message_seen === 'no' && arr_chat[i].from !== getUid() && arr_chat[i].from !== getUserData().waba && arr_chat[i].from !== getUserData().mess) {
              dem += 1
            }
            temp_chat.chat.push(obj_chat)

            if (obj_chat.type !== '' && obj_chat.type !== 'chat' && !obj_chat.message.includes('https://')) {
              data_up_media.push(obj_chat.message)
            }
          }
          temp_chat.unseenMsgs = dem
          chat = temp_chat
          chats[chats.length] = temp_chat
          localStorage.setItem("chat_data", JSON.stringify(chats))
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  if (data_up_media.length > 0) {
    sessionStorage.setItem('upmedia', JSON.stringify(data_up_media))
  }

  // const response = await axios.get('/apps/chat/get-chat', { id })
  // await dispatch(getChatContacts())
  dispatch(reloadDataChats())

  // Cập nhật thông tin user khi chọn chat
  const userDfString = localStorage.getItem('user_df');
  let currentUser = null;
  if (userDfString) {
    try {
      currentUser = JSON.parse(userDfString);
    } catch (error) {
      console.error('Error parsing user_df:', error);
    }
  }

  return {
    chat,
    contact,
    currentUser
  }
})

export const updateHeart = createAsyncThunk('appChat/updateHeart', async (id, { dispatch }) => {
  const userId = Number(sessionStorage.getItem("active_chat"))
  const chats = JSON.parse(localStorage.getItem("chat_data"))
  let chat = chats.find(c => c.userId === userId)
  if (userId !== getUid() && userId !== getUserData().waba && userId !== getUserData().mess) {
    const index_up = chats.findIndex(c => c.userId === userId)
    if (index_up >= 0) {
      const index_up2 = chats[index_up].chat.findIndex(c => c.kid === id)
      if (index_up2 >= 0) {
        const curr_h = chats[index_up].chat[index_up2].heart
        let like = 1
        if (curr_h) {
          like = 0
        }
        chats[index_up].chat[index_up2].heart = like
        localStorage.setItem("chat_data", JSON.stringify(chats))
        dispatch(selectChat(sessionStorage.getItem("active_chat")))

        const datas = new FormData()
        datas.append('id', id)
        datas.append('heart', like)
        const config = {
          method: 'post',
          url: `${Constants.API_CLOODO}messages/change-status-message`,
          headers: getHearderAuth(),
          data: datas
        }
        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data))
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }
  }

  return { chat }
})

export const updateMsgChat = createAsyncThunk('appChat/updateMsgChat', async (obj, { dispatch }) => {
  // const userData = getUserData()
  const chats = JSON.parse(localStorage.getItem("chat_data"))

  let activeChat = chats.find(chat => chat.userId === obj.sendUserId)

  const newMessageData = {
    message: obj.message,
    time: new Date(),
    senderId: obj.sendUserId,
    type: 'chat'
  }
  // If there's new chat for user create one
  // console.log(obj)
  if (obj.contactId === getUid()) {
    const contacts = JSON.parse(localStorage.getItem("contact_data"))
    const newContact = contacts.find(contact => contact.id === obj.sendUserId)
    // console.log(newContact)
    if (newContact === undefined) {
      contacts.push(obj.sendInfor)
      localStorage.setItem("contact_data", JSON.stringify(contacts))
    }
    if (activeChat === undefined) {
      chats.push({
        id: obj.sendUserId,
        userId: obj.sendUserId,
        unseenMsgs: 1,
        chat: [newMessageData]
      })
      activeChat = chats[chats.length - 1]
    } else {
      activeChat.chat.push(newMessageData)
    }
  }

  try {
    console.log(chats, activeChat);
    if (activeChat) {
      const index_up = chats.findIndex(c => c.id === activeChat.id)
      chats[index_up] = activeChat
      chats[index_up].unseenMsgs += 1
    }
    localStorage.setItem("chat_data", JSON.stringify(chats))
  } catch (error) {
    console.log(error)
  }

  const sendId = sessionStorage.getItem("active_chat")
  // console.log(Object.prototype.toString.call(sendId).slice(8, -1).toLowerCase())
  if (sendId) {
    if (obj.contactId === parseInt(sendId) || obj.sendUserId === parseInt(sendId)) {
      setTimeout(() => {
        dispatch(selectChat(sessionStorage.getItem("active_chat")))
      }, 500)
    }
  }

  return dispatch(reloadDataChats())
})

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
  // console.log(obj)
  const userData = getUserData()
  let send_id = getUid()
  if (obj.display_now === 1) {
    send_id = userData.waba
  } else {
    const FormData = require('form-data')
    const data_s = new FormData()
    data_s.append('message', obj.message)
    data_s.append('user_id', obj.contact.id)
    if (obj.type) {
      data_s.append('type', obj.type)
    }

    const config = {
      method: 'post',
      url: `${Constants.REST_API_WORKSUITE_V3}messages/send`,
      headers: getHearderAuth(),
      data: data_s
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        // sessionStorage.clear()
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  let obj_res = {}
  const chats = JSON.parse(localStorage.getItem("chat_data"))

  let activeChat = chats.find(chat => chat.userId === obj.contact.id)
  const newMessageData = {
    message: obj.message,
    time: new Date(),
    senderId: send_id,
    type: (obj.type ? obj.type : 'chat')
  }
  // If there's new chat for user create one
  let isNewChat = false
  if (activeChat === undefined) {
    isNewChat = true
    chats.push({
      id: obj.contact.id,
      userId: obj.contact.id,
      unseenMsgs: 0,
      chat: [newMessageData]
    })
    activeChat = chats[chats.length - 1]
  } else {
    activeChat.chat.push(newMessageData)
  }
  obj_res = { newMessageData, id: obj.contact.id }
  if (isNewChat) obj_res.chat = activeChat

  const index_up = chats.findIndex(c => c.id === activeChat.id)
  chats[index_up] = activeChat

  if (localStorage?.getItem('last_role_use_login') === 'client') {
    const send_auto = 5;
    if (obj.contact.id === send_auto) {
      const hasSkypeInvite = activeChat.chat.some(message =>
        message.message.includes('https://join.skype.com/invite/yhgM2jFutRxC')
      );

      if (!hasSkypeInvite) {
        const autoReplyMessage = {
          message: `Hello ${userData.fullName}! Thank you for reaching out to us. We've received your message and will respond as soon as possible. \nFor faster assistance, you can contact us directly via WhatsApp: +84 97 356 87 56 or Skype: <a href="https://join.skype.com/invite/yhgM2jFutRxC" target="_blank">https://join.skype.com/invite/yhgM2jFutRxC</a>. \nHave a great day!`,
          time: new Date(),
          senderId: send_auto,
          type: 'chat',
        };

        activeChat.chat.push(autoReplyMessage);
      }
    }
  }

  localStorage.setItem("chat_data", JSON.stringify(chats))

  await dispatch(selectChat(obj.contact.id))
  return { obj_res }
})

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    contacts: [],
    userProfile: {},
    selectedUser: {},
    isLoading: false,
    isLoadingChatContact: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChatContacts.pending, (state) => {
        state.isLoadingChatContact = true
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        // console.log(action)
        state.isLoadingChatContact = false
        state.chats = action.payload.chatsContacts
        state.contacts = action.payload.contacts
      })
      .addCase(selectChat.pending, (state) => {
        state.isLoading = true
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        // console.log(action)
        state.isLoading = false
        state.selectedUser = action.payload
      })
      .addCase(reloadDataChats.fulfilled, (state, action) => {
        // console.log(action.payload.chatsContacts.length)
        state.chats = action.payload.chatsContacts
        state.contacts = action.payload.contacts
      })
      .addCase(updateMsgChat.fulfilled, (state, action) => {
        // console.log(action)
        if (action.payload.payload) {
          state.chats = action.payload.payload.chatsContacts
          state.contacts = action.payload.payload.contacts
        }
      })
      .addCase(updateHeart.fulfilled, (state, action) => {
        // console.log(action)
        state.chats = action.payload.payload.chatsContacts
      })
  }
})

export default appChatSlice.reducer
